import React from 'react';

export const FileRequestCard = ({
  name, description, resourceName, image,
}) => {
  const mailtoAddress = 'info@haniemousavi.com';
  const mailtoSubject = `Request for a PDF copy of your ${resourceName}`;
  const mailtoBody = `Hello Hanie, I am ... and I would like to receive a copy of your ${resourceName}. Thank you.`;
  const mailtoString = `mailto:${mailtoAddress}?subject=${mailtoSubject}&body=${mailtoBody}`;

  return (
    <a
      href={mailtoString}
      className="w-11/12 my-2 mx-auto bg-white overflow-hidden rounded-lg shadow-lg hover:shadow-xl transform-gpu transition-transform hover:scale-105"
    >
      {image}

      <div className="leading-tight p-2 md:p-4">
        <h2 className="text-lg">
          {name}
        </h2>

        <p className="mt-2 text-gray-600 text-sm">
          {description}
        </p>
      </div>
    </a>
  );
};
