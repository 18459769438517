import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";
import { FaFilePdf } from 'react-icons/fa';
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import SEO from "../components/SEO"
import { FileRequestCard } from "../components/FileRequestCard"

const InlineLink = ({ children, ...rest }) => (
  <Link
    className="border-b-4 border-green-400 hover:bg-green-200"
    {...rest}
  >
    {children}
  </Link>
)

const ResumePage = () => {
  return (
    <main className="site">
      <SEO
        title="Resume - Hanie Mousavi"
        description="Here you can access my resume / CV and download it in PDF format."
      />

      <Navbar />

      <div className="site-content slim">
        <div className="w-full gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-16 text-xl text-gray-800 leading-normal">
          <FileRequestCard
            name={(
              <div>
                <FaFilePdf className="inline" />
                <span className="ml-1">Request PDF of CV</span>
              </div>
            )}
            description="1.5 MB"
            resourceName="CV"
            image={(
              <StaticImage
                className="rounded-lg"
                src="../images/cv-thumbnail-en.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                objectFit="contain"
                imgStyle={{objectFit: 'contain'}}
                width={400}
                height={250}
              />
            )}
          />

          <FileRequestCard
            name={(
              <div>
                <FaFilePdf className="inline" />
                <span className="ml-1">Request PDF of CV (in German)</span>
              </div>
            )}
            description="1.6 MB"
            resourceName="CV in German"
            image={(
              <StaticImage
                className="rounded-lg"
                src="../images/cv-thumbnail-de.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                objectFit="contain"
                imgStyle={{objectFit: 'contain'}}
                width={400}
                height={250}
              />
            )}
          />
        </div>
      </div>

      <Footer />
    </main>
  )
}

export default ResumePage
